
export default {
  props: ['multiselect', 'extensions', 'sizeLimit', 'filePicker'],
  data: () => ({
    clientId: process.env.ONEDRIVE_CLIENT_ID,
    scriptLoaded: false,
    url: '',
    folderId: '',
    driveId: '',
    accessToken: ''
  }),
  watch: {
    scriptLoaded (val) {
      if (val) {
        this.$emit('scriptLoaded')
      }
    }
  },
  mounted () {
    const element = document.getElementById('FileInputDropdown')
    if (!this.filePicker || element.matches(':hover')) {
      this.loadScript()
    }
  },
  methods: {
    async loadScript () {
      return new Promise((resolve, reject) => {
        if (window.OneDrive) {
          this.scriptLoaded = true
          resolve(true)
        } else {
          const oneDriveScript = document.createElement('script')
          oneDriveScript.onload = () => {
            this.scriptLoaded = true
            resolve(true)
          }
          oneDriveScript.setAttribute('src', 'https://cdn.freeconvert.com/onedrive-v7.2.js')
          document.body.appendChild(oneDriveScript)
        }
      })
    },
    async openChooser () {
      await this.loadScript()
      const options = {
        clientId: this.clientId,
        action: "query",
        multiSelect: true,
        openInNewWindow: true,
        advanced: {
          queryParameters: "select=id,name,size,@microsoft.graph.downloadUrl",
          endpointHint: "api.onedrive.com",
          redirectUri: `${window.location.origin}/onedrive.html`,
        },
        success: (files) => {
          this.$emit('picked', files?.value.map(file => ({ name: file.name, url: file['@content.downloadUrl'], size: file.size, uploadMethod: 'one_drive' })))
        },
        cancel: () => this.$emit('cancel'),
        error: function(error) {
          console.log('onedrive error', error);
        },
      }

      window.OneDrive.open(options)
    },
    async saveFile(url) {
      this.url = url;
      await this.loadScript();
      const options = {
        clientId: this.clientId,
        action: "query",
        viewType: 'all',
        openInNewWindow: true,
        advanced: {
          // Request additional parameters when we save the file
          queryParameters: "select=id,name,file,folder,children,root,parentReference",
          endpointHint: "api.onedrive.com",
          redirectUri: `${window.location.origin}/onedrive.html`,
          // we need edit permission to upload a file
          scopes: 'Files.ReadWrite.All',
        },
        success: async (selection) => {
          if (selection.accessToken == null) {
            // An error occurred and we don't have a token available to save.
            this.$emit("cancel");
            $nuxt.$store.commit("setAlert", {
              type: "danger",
              sticky: true,
              msg: this.$t("onedrive_failed"),
            });
            return;
          }

          this.detectDriveInfo(selection);
          const taskBody = await this.generateOnedriveTaskBody(selection);
          this.$emit("downloadToOneDrive", taskBody);
        },
        cancel: () => {
          this.$emit("cancel");
        },
        error: (e) => {
          this.$emit("cancel");
          $nuxt.$store.commit("setAlert", {
            type: "danger",
            sticky: true,
            msg: this.$t("onedrive_failed"),
          });
        },
      };
      window.OneDrive.open(options);
    },
    async getFileListFromSelectedFolder() {
      const url = this.generateOnedriveUrl();
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.accessToken,
      };
      try {
        const res = await fetch(url, {
          method: "GET",
          headers,
        });
        const resObj = await res.json();
        return resObj.value || []
      } catch (error) {
        console.log('error fetching file names from the selected onedrive folder', error);
        return []
      }
    },
    async generateOnedriveTaskBody() {
      const fileList = await this.getFileListFromSelectedFolder();
      return {
        drive: this.driveId,
        folder: this.folderId,
        token: this.accessToken,
        filename: this.getNewFileName(decodeURIComponent(this.url.split("/").pop()), fileList)
      };
    },
    generateOnedriveUrl() {
      return `https://api.onedrive.com/v1.0/drives/${this.driveId}/items/${this.folderId}/children`;
    },
    detectDriveInfo(selection) {
      this.accessToken = selection.accessToken;
      this.driveId = selection.value[0].parentReference.driveId;
      // assume, user selected a folder
      this.folderId = selection.value[0].id;
      // but if user selected a file then take folder_id from the parentReference object
      if (selection.value[0].file && selection.value[0].parentReference.id) {
        this.folderId = selection.value[0].parentReference.id
      }
    },
    getNewFileName(fileName, fileList) {
      let newFileName = fileName;
      let count = 1;

      // Check if the given file name already exists in the file list
      const fileNameExists = fileList.some((file) => file.name === fileName);

      // If the given file name already exists, generate a new file name
      while (fileNameExists) {
        // Remove the file extension (if any)
        const fileNameParts = fileName.split(".");
        const baseName = fileNameParts.slice(0, -1).join(".");
        const extension = fileNameParts.slice(-1)[0];

        // Append the count to the base name and reattach the extension
        newFileName = `${baseName} ${count}.${extension}`;

        // Check if the new file name already exists
        const newNameExists = fileList.some((file) => file.name === newFileName);

        // If the new file name does not exist, break the loop
        if (!newNameExists) {
          break;
        }

        // Increment the count for the next iteration
        count++;
      }

      return newFileName;
    },
    open () {
      this.openChooser()
    }
  }
}
